"use client";

import classNames from "classnames/bind";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { BlocksAnimatedProductTeaserCard } from "../../../__generated__/contentful-types";
import { ContentfulResponsiveImage } from "../../contentful-responsive-image/contentful-responsive-image";
import { CosmosButton } from "@cosmos/web/react";
import { Kicker } from "../../kicker/kicker";
import styles from "./animated-product-teaser-card.module.css";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";

const SCROLL_THRESHOLD = 0.5;
const cx = classNames.bind(styles);

export const AnimatedProductTeaserCard = ({
  block,
}: {
  block: BlocksAnimatedProductTeaserCard;
}) => {
  const backgroundColorStyle = block.backgroundColor
    ? { backgroundColor: `${block.backgroundColor}` }
    : null;

  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: SCROLL_THRESHOLD,
  });

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        {/* todo: backgroundColor is required in the CMS so always true */}
        <a
          className={cx("card", {
            "card--has-gradient": block.backgroundColor,
            "card--has-background": block.backgroundImage,
          })}
          href={block.linkUrl ?? undefined}
          style={backgroundColorStyle ?? undefined}
        >
          {block.backgroundImage && (
            <div aria-hidden="true" className={cx("background-image-wrapper")}>
              <ContentfulResponsiveImage
                sizes="(min-width: 1000px) 1835px, (min-width: 650px) 600px, 100vw"
                className={cx("background-image", {
                  "background-image--animating": hasIntersected,
                })}
                asset={block.backgroundImage}
                alt=""
              />
              <div
                className={cx("background-image-gradient")}
                style={{
                  backgroundImage: `radial-gradient(ellipse at bottom, var(--color-transparent) 50%, ${block.backgroundColor})`,
                }}
              />
            </div>
          )}
          <div className={cx("text-content")}>
            {/* HACK: leave this div here to `space-between` pushes the main content area to the bottom */}
            <div>
              {block.logoHeading && (
                <ContentfulResponsiveImage
                  className={cx("logo")}
                  asset={block.logoHeading}
                  alt={block.logoHeading.description ?? ""}
                />
              )}
            </div>

            <div>
              {(block.name || block.title) && (
                <div className={cx("heading-group")}>
                  {block.name && (
                    <span className={cx("kicker-wrapper")}>
                      <Kicker kind="bare" text={block.name} />
                    </span>
                  )}
                  {block.title && !block.logoHeading && (
                    <h3 className={cx("title")}>{block.title}</h3>
                  )}
                </div>
              )}
              <p
                className={cx("text", {
                  "text--has-disclaimer": block.disclaimer,
                })}
              >
                {block.description}
              </p>
              {block.disclaimer && (
                <span
                  className={cx("disclaimer", {
                    "disclaimer--centered": block.centerDisclaimer,
                  })}
                >
                  {block.disclaimer}
                </span>
              )}
              {block.linkUrl && block.linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    decorative={true}
                    kind={
                      block.backgroundColor || block.backgroundImage
                        ? "tertiary"
                        : "primary"
                    }
                    appearance={
                      block.backgroundColor || block.backgroundImage
                        ? "light"
                        : "dark"
                    }
                    size="large"
                    className={cx("button")}
                  >
                    {block.linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {block.animationStartImage && block.animationEndImage && (
            <div className={cx("image-wrapper")} ref={ref}>
              <ContentfulResponsiveImage
                loading="eager"
                sizes="(min-width: 1000px) 612px, (min-width: 650px) 600px, 300px"
                className={cx("end-image", {
                  "end-image--animating": hasIntersected,
                })}
                asset={block.animationEndImage}
                alt={
                  block.animationEndImage.description ||
                  "Teaser image of the can"
                }
              />
              <ContentfulResponsiveImage
                loading="eager"
                sizes="(min-width: 1000px) 612px, (min-width: 650px) 600px, 300px"
                className={cx("start-image", {
                  "start-image--animating": hasIntersected,
                })}
                asset={block.animationStartImage}
                alt={
                  block.animationStartImage.description ||
                  "Teaser image of the can"
                }
              />
              <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              />
              {/* High Sugar mark currently not implimented */}
              {/* <dietaryMarks
                kind="high-sugar"
                className={cx("dietary-mark-high-sugar")}
                sugarFree={true}
              /> */}
            </div>
          )}
        </a>
      </div>
    </div>
  );
};
